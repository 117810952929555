@import '../common/color.less';

.joinplace-input {
  >.ant-typography {
    text-align: left;
    display: flex;
    text-indent: 10px;
  }

  .ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    gap: .1rem;
    border: 1px solid #d9d9d9;
    background: @white 0% 0% no-repeat padding-box;
    border-radius: 6px;

    &.ant-input-affix-wrapper-focused,
    &:hover {
      border: 1px solid @primary;
      box-shadow: 0px 2px 0px @shadow;
      border: 1px solid @primary;
    }

    .anticon {
      color: @black-700;
    }

  }

  .ant-input-affix-wrapper-lg {
    height: 2.8rem;
  }
}