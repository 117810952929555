.header-fixed {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;

  .ant-page-header-heading {
    display: block;
    flex: 0;
    padding: 1rem;
    margin: auto;

    .ant-page-header-heading-title {
      .logo_avatar {
        height: 3.75rem;
      }
    }
  }

  .ant-page-header-content {
    display: flex;
    flex: 1;
    justify-content: stretch;
    padding: 0;
    overflow: hidden;
  }

  .ant-page-header-footer {
    margin: 0;
  }
}
