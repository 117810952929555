@import '../common/color';

.joinplace-checkbox__group {
  .ant-checkbox-wrapper {
    width: 100%;
    padding: 5px 10px 5px 10px;
    justify-content: center;

    &:first-child {
      margin-top: 10px;
    }
  }
}
