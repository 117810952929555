@import '~antd/dist/antd.less';
@import './components/common/global.less';
@import './components/common/color.less';
@primary-color: @primary;

body,
html {
  font-family: 'Rubik', sans-serif;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  scroll-behavior: smooth;

  .ant-layout-content {
    display: flex;
    min-height: calc(100vh - 375.5px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: #fff;
  }

  .ant-layout-content>.ant-row {
    width: 100%;
  }
}

.ant-layout {
  background: #fff !important;
}

.ant-layout-footer {
  background: @white;
  padding: 1rem;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  margin: 0;
}

.ant-typography {
  margin: 0;

  h1 {
    font-size: 1.875rem;
  }
}

.ant-input-affix-wrapper-lg {
  padding: 15px 11px;
}

.btn-next-step {
  width: 220px;
  height: 57px;
  margin: 0 auto;
  display: block;
  border-radius: 6px;
}

.ant-avatar>img {
  object-fit: inherit;
}