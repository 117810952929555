@import '../common/color';

.joinplace-button {
  border-radius: 6px!important;

  &.btn-secondary {
    background: @black-300;
    color: @black-600;

    &:hover {
      background: @black-600;
      color: @white;
      border: 1px solid @black-600;
    }
  }
}
